@media (max-width:1600px) {
  html {
      font-size: 95%;
    }
    .menu__list {
      // justify-content: flex-start;
      // padding-right: 130px;
    }

    .menu__link {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 1rem;
    }

    .menu__item {
      margin: 0 10px;
    }
}
