@media (max-width: 768px) {
  .header__right {
    width: 160px;
  }

  .btn-rezervasyon {
    width: 120px;
    font-size: 1rem;
  }

  .lang-select {
    width: 38px;
  }

  .logo {
    width: 170px;
  }

  .banner {
    height: 300px;
    padding-top: 200px;
  }

  #video-slider iframe {
    vertical-align: middle;
    width: 250% !important;
    max-width: 250%;
    margin-left: -75%;
    height: 100% !important;
  }

  .home-slider .swiper-slide {
    height: auto;
    padding: 125% 0 0;
    overflow: hidden;
  }

  .section {
    padding: 30px 0;
  }

  .bowl__title {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .bowl__text {
    font-size: 0.8rem;
  }

  .footer__left {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .footer__mid {
    width: 100%;
  }

  .about-box{
    height: 270px;
  }

  .footer__right {
    width: 100%;
    margin-top: 30px;
  }

  .about-box__title{
    font-size: 1.8rem;
  }

  .about-box__text{
    font-size: 1rem;
  }

  .beach-box{
    flex-direction: column-reverse;
    &__img{
      width: 100%;
      height: 40vh;
    }

    &__content{
      width: 100%;
      padding: 30px 15px;
    }
  }

  .beach-boxes .beach-box:nth-of-type(even){
    flex-direction: column-reverse;
  }

  .banner--img{
    padding-top: 200px;
    height: 400px;
  }

  .reservation-btn{
    font-size: 1rem;
  }

  .show-mobile{
    display: block;
  }

  .hide-mobile{
    display: none;
  }

  .rooms{
    min-height: inherit;
  }

  .banner-slider {
    img {
      height: 200px;
    }
  }
}
