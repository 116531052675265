@media (max-width:1200px) {
  html {
     font-size: 85%;
   }

   .header__left {
     width: 220px;
   }

   .header__right {
     width: 220px;
   }

   .btn-rezervasyon {
     width: 160px;
   }

   .header__left a svg {
     width: 16px;
     height: 16px;
   }

   .rooms__img{
     height: 500px;
   }

   .restaurant-content{
     padding-top: 30px;
   }

   .beach-box__content{
     padding: 50px 30px;
   }

}
