// @font-face {
//   font-family: 'Boska', serif;
//   src: url('fonts/Boska-Variable.woff2') format('woff2'),
//        url('fonts/Boska-Variable.woff') format('woff'),
//        url('fonts/Boska-Variable.ttf') format('truetype');
//        font-weight: 200 900;
//        font-display: swap;
//        font-style: normal;
// }

.title {
  display: block;
  font-weight: 300;
  font-size: 2.5rem;
  font-family: $taviraj;

  &--big {
    font-size: 3rem;
  }

  &--semi {
    font-size: 2rem;
  }

  &--medium {
    font-size: 1.8rem;
  }

  &--smaller {
    font-size: 1.3rem;
  }
}
