@media (max-width: 992px) {
  html {
    font-size: 80%;
  }

  .logo {
    margin-left: 15px;
    width: 200px;
  }

  .header__left {
    display: none;
  }

  .menu {
    .header__left {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 15px;
      width: auto;
      height: auto;
      height: 50px;
      border: none;

      a {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__list {
      display: none;
    }
  }

  .btn-mobil {
    display: inline-block;
  }

  .header__right {
    height: 60px;
  }

  .btn-rezervasyon {
    height: 60px;
    line-height: 60px;
  }

  .lang-select__current {
    height: 60px;
    line-height: 60px;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    height: 52px;
  }

  .lang-select__dropdown {
    top: 60px;
    z-index: 30;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 1.2rem;
  }

  .header--fixed {
    top: -61px;
  }

  .home-about__content {
    padding-left: 0;
  }

  .home-spa__slider {
    padding-right: 0;
    padding-bottom: 150px;

    .spa-slider {
      width: 500px;
      max-width: 100%;
      margin-left: 30px;
      margin-right: 0;
    }
  }

  .spa-nav {
    max-width: calc(100% - 30px);
    left: 30px;
    margin-left: 0;
    margin-right: 0;
  }

  .footer {
    .container {
      max-width: 100%;
    }
  }

  .social a {
    margin: 6px;

    .bi {
      width: 16px;
      height: 16px;
    }
  }

  .banner--restaurant {
    padding-top: 200px;
    height: 500px !important;
  }

  .restaurant-page > .container {
    flex-direction: column-reverse;
  }

  .banner--restaurant .banner__title {
    font-size: 4rem;
  }

  .restaurant-img {
    width: 100%;
    margin-top: -60px;

    img {
      height: 350px;
    }
  }

  .restaurant-content {
    width: 100%;
  }

  .gallery-slider-wrapper {
    margin-top: 30px;
  }

  .room-detail{
    &__left{
      display: none;
    }

    &__right{
      width: 100%;
    }
  }

  .show-992{
    display: block;
  }
}
