@media (max-width: 576px) {
  .mobil-menu-li {
    width: 100%;
  }

  .mobil-menu-link {
    text-align: left;
    font-size: 16px;
  }

  .mobil-menu {
    padding: 15px;
  }

  .mobil-address {
    padding: 15px;

    .contact-link,
    .contact-text {
      color: #fff;
      font-size: 1.2rem;
    }
  }

  .food__left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    top: auto;
  }

  .food__category {
    width: 50%;
    right: auto;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    font-size: 1.1rem;

    img {
      margin-right: 0;
      margin-bottom: 5px;
    }

    strong {
      display: block;
      width: 100%;
    }

    &:last-child,
    &:nth-of-type(2) {
      border: none;
    }
  }

  .food__list {
    width: 100%;
    padding-left: 0;
    border: none;
  }

  .food__left {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dfdfdf;
  }

  .food__category {
    font-size: 1rem;
    padding-right: 15px;
    margin-right: 15px;
    padding-bottom: 0;

    &:last-child {
      border: none;
    }
  }

  .food__item {
    padding: 10px 0;
  }

  .food__title {
    font-size: 0.9rem;
  }

  .food__category:nth-of-type(2) {
    border-right: 1px solid #dfdfdf;
  }

  .tum-liste {
    display: block;
    width: 100%;
    padding: 0.475rem 1.55rem;
    font-size: 0.8rem;
    background: $primary;
    color: #fff;
    text-align: center;

    &:Hover {
      background: $hover_primary;
      color: #fff;
    }
  }

  .slider-title {
    font-size: 8vw;
    padding: 0 15px;
  }

  .mouse__icon {
    display: none;
  }

  .mouse__icon--mobile {
    display: inline-block;
  }

  .footer__menu {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    justify-content: center;
  }

  .footer__bottom {
    text-align: center;

    span {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    nav{
      margin-left: auto;
      margin-right: auto;
      display: inline-block;

      a{
        margin: 4px;
      }
    }
  }
}
