@media (max-width:1400px) {
  html {
    font-size: 90%;
  }

  .restaurant-content{
    width: calc(100% - 385px);
  }

  .restaurant-img {
    width: 360px;
    margin-top: -200px;

    img{
      height: 540px;
    }
  }
}
