$text: #5E564D;
$primary: #a9ad70;
$secondary: #fff7e9;
$hover_primary: #91964a;
$hover_secondary: #eeddbe;
$line: #eee;
$black: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$white: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-8: rgba(255, 255, 255, 0.8);

$light: #F6F4F1;
$dark: #2E2C2B;

.color--primary {
  color: $primary;
}

.color--secondary {
  color: $secondary;
}

.color--text {
  color: $text;
}

.bg--primary {
  background: $primary;
}

.bg--secondary {
  background: $secondary;
}
