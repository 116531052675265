@import "reset", "colors", "mixin", "global", "hamburgers", "bootstrap", "swiper", "typography", "buttons", "aos";

#__bs_notify__ {
  display: none !important;
}

.mobile-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;

  &.active {
    display: block;
  }
}

.mobile {
  display: none;

  &.active {
    display: block;
  }
}

.btn-mobil {
  display: none;
}

.home-slider {
  background: #423d3e;
}

.header {
  @extend .efekt;
  @extend .flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  &__left {
    @extend .flex;
    justify-content: center;
    padding: 0 15px;
    width: 250px;
    height: 100px;
    border-right: 1px solid $white-3;
    color: #b6b6b6;

    a {
      @include efekt(color);
      margin: 0 10px;
      color: #fff;
      text-decoration: none;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        color: $primary;
      }
    }

    span {
      position: relative;
      top: 2px;
    }
  }

  &__right {
    @extend .flex;
    width: 250px;
    height: 100px;
    border-left: 1px solid $white-3;
  }

  &--fixed {
    top: -100px;
    background: rgba(#000000, 0.65);
    backdrop-filter: blur(4px);
  }

  &--page {
    background: #fff;

    .outside-link {
      &:last-child {
        border-color: $line;
      }
    }

    .header__left,
    .header__right {
      border-color: $line;
    }

    .header__left {
      a {
        color: $text;

        &:hover {
          color: $primary;
        }
      }
    }

    .menu {
      border-color: $line;

      &__link {
        color: $text;
      }
    }

    .logo img {
      // filter: brightness(100%) invert(1);
    }

    .btn-rezervasyon {
      border-color: $line;
      color: $primary;
    }

    .lang-select__current {
      color: $text;
    }

    .lang-select__dropdown {
      border-color: $white;
    }

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background: #2e2e2e;
    }
  }
}

.logo {
  width: 367px;
}

.menu {
  @extend .efekt;
  position: relative;
  width: 100%;
  border-top: 1px solid $white-3;
  border-bottom: 1px solid $white-3;
  font-family: $taviraj;

  .header__left {
    display: none;
  }

  &__list {
    @extend .flex;
    justify-content: center;
  }

  &__item {
    margin: 0 15px;

    &:last-child {
      display: none;
    }
  }

  &__link {
    @include efekt(color);
    display: block;
    padding: 20px 15px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;

    &:hover {
      color: $primary;
    }
  }
}

.btn-rezervasyon {
  @extend .efekt;
  display: inline-block;
  width: 190px;
  height: 100px;
  border-right: 1px solid $white-3;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: $taviraj;
  line-height: 100px;

  &:hover {
    background: $primary;
    color: #fff;
  }
}

.lang-select {
  position: relative;
  position: relative;
  z-index: 10;
  width: 59px;
  font-family: $taviraj;

  &__current {
    @extend .efekt;
    display: block;
    height: 100px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 1.1rem;
    line-height: 100px;

    &:hover {
      background: $primary;
      color: #fff;
    }
  }

  &:hover {
    .lang-select__dropdown {
      display: block;
    }

    & > .lang-select__current {
      background: $primary;
      color: #fff;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100px;
    display: none;
    width: 100%;
    border-top: 1px solid #343434;
    background: $primary;
    animation: fadeIn 0.5s;

    a {
      @include efekt(color);
      display: block;
      padding: 15px;
      color: #fff;
      text-align: center;
      text-decoration: none;

      &:hover {
        color: #3e3e3e;
      }
    }
  }
}

.mouse {
  position: absolute;
  right: 0;
  bottom: 10%;
  left: 0;
  z-index: 2;
  margin: 0 auto;
  width: 16px;
  text-decoration: none;

  &__text {
    position: absolute;
    top: -45px;
    left: -40px;
    display: block;
    width: 100px;
    color: #fff;
    text-align: center;
    font-size: 0.8rem;
  }

  &__icon {
    animation: wiggle 3s infinite;

    &--mobile {
      display: none;
    }
  }

  &__line {
    position: relative;
    display: none;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    width: 1px;
    height: 140px;
    background: $primary;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 1px;
      height: 50%;
      background: #fff;
      content: "";
    }
  }
}
@keyframes wiggle {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

#video-slider iframe {
  height: 103% !important;
  vertical-align: middle;
}

.home-slider .swiper-slide {
  padding: 55% 0 0;
  height: auto;
}

.home-slider .swiper-slide img {
  height: auto;
}

.home-slider {
  position: relative;
}
@media(max-width:576px) {
  #video-slider iframe {
    margin-left: -50%;
    width: 200% !important;
    height: 100% !important;
    vertical-align: middle;
  }

  .home-slider .swiper-slide {
    overflow: hidden;
    padding: 90% 0 0;
    height: auto;
  }
}

.text {
  font-size: 1rem;
  line-height: 1.7;

  &--small {
    font-size: 0.9rem;
  }

  &--smaller {
    font-size: 0.8rem;
  }

  &--big {
    font-size: 1.1rem;
  }

  &--bigger {
    font-size: 1.2rem;
  }

  &--huge {
    font-size: 1.3rem;
  }

  p {
    display: block;
    margin-bottom: 15px;
    font-family: $excon !important;
  }
}

.slider-title {
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;
  z-index: 30;
  margin: 0 auto;
  max-width: 100%;
  width: 600px;
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 4vw;
  font-family: $taviraj;
}

.button {
  @extend .efekt;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  color: $text;
  text-decoration: none;
  font-size: 1.1rem;
  font-family: $taviraj;
  cursor: pointer;

  .bi {
    @include efekt(margin-left);
    margin-left: 12px;
  }

  &:Hover {
    color: $primary;

    .bi {
      margin-left: 16px;
    }
  }

  &--white {
    color: #fff;
  }
}

.section {
  position: relative;
  padding: 100px 0;
}

.home-about {
  overflow: hidden;
  background: $light;

  &__img {
    padding: 0 50px;

    img {
      width: 100%;
    }
  }

  &__content {
    padding-left: 60px;
    max-width: 100%;
    width: 600px;
  }
}

.title-section {
  text-align: center;

  .title {
    margin-bottom: 12px;

    small {
      display: block;
      margin-bottom: 6px;
      color: $primary;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 300;
      font-size: 1.2rem;
      font-family: $excon;
    }
  }

  .text {
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 600px;
  }
}

.rooms {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100vh;

  &__aside {
    position: sticky;
    top: 80px;
    width: 350px;
  }

  &__title {
    display: block;
    margin-bottom: 12px;
    font-weight: 200;
    font-size: 1.4rem;
  }

  &__list {
    padding-left: 30px;
  }

  &__item {
    margin: 4px 0;
  }

  &__link {
    @extend .efekt;
    @extend .flex;
    padding: 8px 0;
    color: $text;
    text-decoration: none;
    font-weight: 300;
    font-size: 1.5rem;
    font-family: $taviraj;

    .bi {
      @extend .efekt;
      margin-right: 12px;
      opacity: 0;
    }

    &.active,
    &:hover {
      color: $primary;

      .bi {
        margin-right: 0;
        opacity: 1;
      }
    }
  }

  &__content {
    padding-left: 50px;
    width: calc(100% - 350px);
  }

  &__big {}

  &__img {
    position: absolute;
    display: none;
    overflow: hidden;
    height: 80vh;
    animation: fadeIn 0.5s;

    img {
      width: 100%;
      height: 100%;
      transition: all 8s linear;
      object-fit: cover;
    }

    &.active {
      display: block;
    }

    &:hover {
      img {
        max-width: 120%;
        width: 120%;
        height: 120%;
      }
    }
  }
}

.home-restaurant {
  background: #2E2C2B;
}

.home-restaurant {
  .container {
    @extend .flex;
  }

  .bowl {
    width: 45%;
    color: #fff;
  }
}

.bowl {
  @extend .efekt;
  outline: transparent;
  color: inherit;
  text-align: center;
  text-align: center;
  text-decoration: none;

  &__img {
    transition: all 30s linear;
    transform: rotate(0deg);
  }

  &__title {
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 2.5rem;
    font-family: $taviraj;
  }

  &__text {
    display: block;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    max-width: 100%;
    width: 500px;
    opacity: 0.7;
  }

  &:Hover {
    .bowl__img {
      transform: rotate(360deg);
    }

    .button {
      color: $primary;

      .bi {
        margin-left: 16px;
      }
    }
  }
}

.banner-content {
  position: relative;
  padding-top: 200px;
  padding-bottom: 100px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.4);
    content: '';
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    position: relative;
    z-index: 6;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 560px;
    color: #fff;
    text-align: center;
  }

  .title-section {
    margin-bottom: 140px;
  }
}

.custom-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-arrow {
  @extend .efekt;
  margin: 12px;
  width: 48px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.event {
  position: relative;

  &__img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  &__content {
    @extend .efekt;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.6);
    color: #fff;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }

    .button {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 4;
    }
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 1.5rem;
    font-family: $taviraj;
  }

  &__text {
    font-size: 0.9rem;
  }
}

.home-spa {
  position: relative;
  overflow-x: hidden;

  &__slider {
    @extend .efekt;
    position: relative;
    z-index: 2;
    padding-top: 80px;
    padding-right: 30%;
    padding-bottom: 250px;
    width: 100%;
    color: $secondary;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(120deg, #000 -4.73%, rgba(0, 0, 0, 0.00) 61.43%);
      content: '';
    }

    .swiper-slide {
      @include efekt(opacity);
      z-index: 2;
      opacity: 0.2;
      user-select: none;
    }

    .swiper-slide-active {
      opacity: 1;
    }
  }

  &__features {
    padding: 30px 0;
    background: $secondary;

    .swiper-wrapper {
      transition-timing-function: linear!important;
    }
  }
}

.spa-nav {
  position: relative;
  z-index: 6;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  width: 365px;

  &__arrows {
    @extend .flex;
    margin-bottom: 10px;
  }

  &__arrow {
    @include efekt(color);
    display: inline-block;
    color: $secondary;
    text-decoration: none;

    .bi {
      width: 30px;
      height: 30px;
    }

    &:hover {
      color: $primary;
    }
  }

  &__progress {
    position: relative;
  }
}

.swiper-pagination-progressbar {
  background: rgba(255, 255, 255, 0.3);
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #fff;
}

.footer {
  padding-top: 30px;
  background: #F6F4F1;
  font-size: 0.9rem;

  .container {
    @extend .flex;
    align-items: flex-start;
  }

  &__left {
    width: 20%;

    .text-content {
      a {
        display: inline-block;
        margin-left: 4px;
        color: inherit;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    font-size: 1.3rem;
    font-family: $taviraj;
  }

  &__mid {
    @extend .flex;
    width: 50%;
  }

  &__menu {
    width: 40%;

    ul {
      @extend .flex;
      align-items: flex-start;

      li {
        margin-bottom: 2%;
        width: 48%;
      }

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }

  &__right {
    width: 20%;
    text-align: center;
  }

  &__bottom {
    @extend .flex;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-top: 1px solid $line;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    @extend .efekt;
    display: inline-block;
    margin: 12px;
    color: $primary;
    text-decoration: none;

    &:hover {
      color: $hover_primary;
    }
  }
}

.page {
  position: relative;
  overflow: hidden;
  margin: 3rem auto;
}

.banner {
  position: relative;
  padding-top: 350px;
  height: 600px;

  &__title {
    display: block;
    color: #fff;
    text-align: center;
    font-weight: 300;
    font-size: 3rem;
    font-family: $taviraj;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    content: '';
  }

  .container {
    position: relative;
    z-index: 4;
  }

  &--restaurant {
    padding-top: 400px;
    height: 900px;

    .banner__title {
      text-align: left;
      font-weight: 300;
      font-size: 5rem;
      font-family: $excon;

      small {
        display: block;
        font-weight: 100;
        font-size: 50%;
      }
    }
  }
}

.img-banner {
  .row {
    margin-right: -5px;
    margin-left: -5px;
  }

  &__item {
    padding-right: 5px;
    padding-left: 5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-box {
  @extend .bgcover;
  position: relative;
  display: block;
  padding: 15px;
  height: 350px;
  color: #fff;
  text-decoration: none;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $black-4;
    content: '';
  }

  &__content {
    @extend .efekt;
    position: relative;
    z-index: 4;
    margin-right: auto;
    margin-left: auto;
    padding: 15px;
    height: 100%;
    border: 1px solid rgba($primary, .3);
    text-align: center;
  }

  &__title {
    display: block;
    margin-bottom: 12px;
    font-weight: 300;
    font-size: 1.4rem;
    font-family: $taviraj;
  }

  &__text {
    font-size: 0.8rem;
    opacity: 0.7;
  }

  &:hover {
    .about-box__content {
      border: 1px solid $primary;
      background: $black-4;
    }
  }
}

.home-rooms {
  &--page {
    .rooms {
      min-height: auto;
    }
  }
}

.reservation-btn {
  @extend .efekt;
  display: inline-flex;
  justify-content: space-between;
  background: $primary;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  font-size: 1.2rem;
  font-family: $taviraj;

  &__text {
    @extend .efekt;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    width: calc(100% - 48px);
    height: 48px;
    text-align: center;
    letter-spacing: 1.4px;
    line-height: 48px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $hover_primary;
  }

  &:hover {
    background: $hover_primary;
  }
}

.room-detail {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;

  &__line {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 1px;
    height: 100%;
    background: $line;
  }

  &__left {
    position: relative;
    width: 330px;

    .title-bg {
      position: relative;
      z-index: 4;
      display: block;
      padding-bottom: 12px;
      background: #fff;

      &.text {
        padding-top: 12px;
      }
    }

    .reservation-btn {
      position: relative;
      z-index: 3;
      margin-top: 150px;
      margin-bottom: 150px;
      box-shadow: 0 -12px 0 0 #fff, 0 12px 0 0 #fff;
    }
  }

  &__right {
    width: calc(100% - 420px);
  }
}

.margin-left {
  margin-right: 0;
}

.room-list {
  position: relative;
  z-index: 4;
  margin-top: 150px;
  padding: 12px 0 12px 40px;
  background: #fff;
  color: #46403A;
  font-size: 1.3rem;
  font-family: $taviraj;

  li {
    margin-bottom: 4px;
    list-style-type: disc;
    font-weight: 300;
  }

  .strong {
    margin-bottom: 15px;
    margin-left: -40px;
    list-style-type: none;
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.fresco {
  cursor: zoom-in;
}

.restaurant-page {
  position: relative;
  z-index: 14;
  padding-bottom: 100px;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  &--bar {
    background: #171717;
    color: #dbdbdb;

    .title {
      color: #fff;
    }

    .restaurant-img {
      background: #171717;

      img {
        border-color: #171717;
      }

      .reservation-btn {
        border-color: #171717;
      }
    }
  }

  &--default {
    .restaurant-img {
      margin-top: -100px;
    }
  }
}

.restaurant-content {
  margin-bottom: 50px;
  padding-top: 110px;
  width: calc(100% - 600px);
}

.restaurant-img {
  position: relative;
  position: relative;
  z-index: 4;
  margin-top: -350px;
  width: 520px;
  background: #fff;

  img {
    width: 100%;
    height: 760px;
    border: 10px solid #fff;
    object-fit: cover;
  }

  .reservation-btn {
    position: absolute;
    right: 0;
    bottom: -14px;
    left: 0;
    margin: 0 auto;
    width: 280px;
    border: 5px solid #fff;
  }
}

.gallery-slider-wrapper {
  margin-top: 150px;
}

.benefits {
  @extend .flex;
  padding: 80px;
  background: #f0e8dd;

  &__item {
    width: 18%;
    text-align: center;
  }

  &__title {
    display: block;
    margin-top: 15px;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: $taviraj;
    line-height: 1.2;
  }
}

.beach-boxes {
  overflow: hidden;

  .beach-box {
    &:nth-of-type(even) {
      flex-direction: row-reverse;
    }
  }
}

.beach-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__img {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 80px;
    width: 50%;
    background: #F6F4F1;
  }
}

.food {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  &__left {
    position: sticky;
    top: 15px;
    width: 250px;
  }

  &__category {
    @extend .efekt;
    position: relative;
    right: -1px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    width: 100%;
    border-right: 1px solid #dfdfdf;
    color: $primary;
    text-decoration: none;
    text-decoration: none;
    font-size: 1.1rem;
    font-family: $taviraj;
    filter: grayscale(1);

    strong {
      font-weight: 300;
    }

    img {
      margin-right: 15px;
      width: 30px;
    }

    &.active,
    &:hover {
      border-color: $primary;
      filter: grayscale(0);
    }

    small {
      display: block;
      font-weight: 300;
      font-size: 0.9rem;
      font-family: $excon;
    }
  }

  &__list {
    padding-left: 30px;
    width: calc(100% - 250px);
    border-left: 1px solid #dfdfdf;
  }

  &__item {
    padding: 15px 0;
    border-bottom: 1px dotted #dfdfdf;
  }

  &__title {
    display: block;
    margin-bottom: 5px;
    color: $text;
    font-weight: 300;
    font-size: 1.5rem;
    font-family: $taviraj;
  }

  &__text {
    color: #545454;
    font-size: 0.9rem;
  }
}

.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  backdrop-filter: blur(4px);
}

.tum-liste {
  display: none;
}

.popup-list {
  position: fixed !important;
  top: 135px!important;
  right: 0;
  left: 0;
  z-index: 998;
  flex-wrap: wrap !important;
  margin: 0 auto;
  padding: 15px;
  width: calc(100% - 30px) !important;
  height: calc(100vh - 160px);
  border-bottom: none !important;
  background: #fff;
  white-space: normal !important;

  .food__category {
    margin: 0;
    padding: 10px;
    font-size: 0.9rem;

    &:nth-of-type(2n) {
      border: none;
    }
  }
}

.show-992 {
  display: none;
}

.pins {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 1340px;

  &__img {
    width: 100%;
  }
}

.pin {
  position: absolute;
  z-index: 4;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;

  .bi {
    @extend .efekt;
    width: 24px;
    height: 24px;
    color: $primary;
  }

  &__content {
    position: absolute;
    top: 16px;
    left: 18px;
    display: none;
    padding: 14px;
    width: 300px;
    background: rgba(#000000, 0.85);
    color: #fff;
    font-size: 0.9rem;
    transition: opacity 0.3s;
    backdrop-filter:blur(8px);
  }

  &__img {
    margin-bottom: 8px;
  }

  &--1 {
    top: 8.8%;
    left: 40.5%;
  }

  &:hover {
    .bi {
      width: 36px;
      height: 36px;
    }

    .pin__content {
      display: block;
      animation: fadeIn 0.5s;
    }
  }
}

.show-mobile {
  display: none;
}

.room-card {
  position: relative;
  display: block;

  img {
    min-height: 250px;
    width: 100%;
    object-fit: cover;
  }

  &__title {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 15px;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.3);
    color: #fff;
    font-weight: 300;
    font-size: 1.4rem;
    font-family: $taviraj;
  }
}

.room-mobile-nav {
  position: relative;
  margin-bottom: 10px;

  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to right, transparent, #fff);
    content: '';
  }

  &__scroll {
    position: relative;
    display: flex;
    overflow-x: scroll;
    padding-right: 40px;
    padding-bottom: 10px;
    white-space: nowrap;
  }

  .tab-link {
    @extend .efekt;
    display: inline-block;
    margin-right: 20px;
    color: $text;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: $taviraj;

    &.active,
    &:hover {
      color: $primary;
    }
  }
}

.room-mobile-swiper {
  padding-bottom: 30px;

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background: $primary;
  }
}

.news {
  &__link {
    @extend .efekt;
    display: block;
    color: $text;
    text-decoration: none;
  }

  &__img {
    margin-bottom: 15px;
    width: 100%;
  }

  &__title {
    @extend .efekt;
    display: block;
    margin-bottom: 6px;
    font-size: 1.8rem;
    font-family: $taviraj;
  }

  &__text {
    font-size: 0.9rem;
  }

  &:Hover {
    .news__title {
      color: $primary;
    }
  }
}

.banner-slider {
  img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
}
@import "style1600", "style1400", "style1200", "mobil-nav", "style992", "style768", "style576";
